@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  font-size: 16px;
  margin-top: 0!important;
}

* {
  cursor: none!important;
}

body {
  @apply h-screen;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-family: sans-serif;
  @apply text-black;
  @apply bg-white;
  @apply font-serif;
}

#page {
	@apply h-screen;
  height: calc(var(--vh, 1vh) * 100);
	overflow: auto;
}

h1 {
  @apply text-h1;
}

img {
  -webkit-user-drag: none;
}

body button:focus, body input:focus {
  @apply outline-none;
}

.post-title, h1.entry-title {
  @apply text-brand;
  @apply uppercase;
}

.bg-fader, .bg-fixed {
  @apply bg-black;
  @apply h-screen;
  @apply left-0;
  @apply fixed;
  @apply top-0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  z-index: 5;
}

a.logo-main {
  @apply fixed;
  top: 10px;
  left: 50px;
  mix-blend-mode: difference;
  z-index: 19;
  user-select: none;
}

.trg-menu__wrapper {
  mix-blend-mode: difference;
  @apply fixed;
  right: 25px;
  z-index: 9;
  // top: 23px;
}

.trg-menu__hold {
  @apply relative;
  @apply flex;
  @apply items-center;
}

.trg-menu__lang, .trg-menu  {
  @apply text-white;
  @apply h-24;
  @apply w-24;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.trg-menu {
  transition: transform .8s cubic-bezier(.16,1,.3,1);
  // margin-left: 2vw;
  // width: 1vw;
  // height: 1vw;
  // background: #fff;
  // border-radius: 50%;
}

.lines-wrapper {
  @apply h-12;
  @apply w-12;
  @apply relative;
}

.lines-wrapper .trg-menu__line {
  @apply absolute;
  @apply block; 
  @apply h-px;
  @apply bg-white;
  @apply w-full;
  top: 50%;
  transition: all 200ms ease-in-out;
  transform-origin: center;
}

.lines-wrapper .trg-menu__line:first-child {
  top: 15px;
  width: 75%;
}

.lines-wrapper .trg-menu__line:last-child {
  width: 50%;
  top: calc(100% - 15px);
}

.trg-menu:hover .lines-wrapper .trg-menu__line {
  @apply w-full;
  transition: width 200ms ease-in-out, top 300ms 700ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0ms cubic-bezier(0.23, 1, 0.32, 1);
}

.trg-menu.animate .lines-wrapper .trg-menu__line {
  @apply w-full;
}

.trg-menu.animate .lines-wrapper .trg-menu__line:first-child, .trg-menu.animate .lines-wrapper .trg-menu__line:last-child {
  transition: width 0ms ease-in-out, top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 700ms cubic-bezier(0.23, 1, 0.32, 1);
  transform: origin;
}

.trg-menu.animate .lines-wrapper .trg-menu__line:nth-child(2) {
  transition: width 0ms ease-in-out, top 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  top: 50%;
  opacity: 0;
}

.trg-menu.animate .lines-wrapper .trg-menu__line:first-child {
  top: 50%;
  transform: rotate(45deg);
  opacity: 1;
}

.trg-menu.animate .lines-wrapper .trg-menu__line:last-child {
  top: 50%;
  transform: rotate(-45deg);
  opacity: 1;
}

#languages-selector {
  @apply opacity-0;
  @apply absolute;
  @apply h-full;
  @apply flex;
  @apply items-stretch;
  visibility: hidden;
  position: absolute;
}

#languages-selector ul {
  @apply h-full;
  @apply flex;
  @apply items-stretch;
}

#languages-selector ul li {
  @apply flex-1;
  @apply text-center;
  @apply flex;
  @apply items-center;
}

#languages-selector ul li::after {
  content: '';
  @apply block;
  @apply w-px;
  @apply bg-white;

}

#languages-selector ul li:last-child:after {
  @apply hidden;
}

#languages-selector ul li a {
  @apply w-12;
  @apply w-12;
  @apply text-white;
  @apply opacity-30;
  @apply block;
  transition: opacity 300ms ease-in-out;
}

#languages-selector ul li.active a, 
#languages-selector ul li:hover a {
  @apply opacity-100;
}

.menu-fixed {
  height: calc(100vh - 100px);
  height: calc(var(--vh, 1vh) * 100 - 100px);
  left: 0;
  position: fixed;
  top: 100px;
  width: 100vw;
  z-index: 8;
  display: flex;
}

.menu-fixed #site-navigation li a, 
.menu-fixed #site-navigation li span {
  mix-blend-mode: difference;
  @apply text-white;
  @apply uppercase;
  @apply whitespace-nowrap;
  font-size: calc(5vw + 25px);
  line-height: 80%;
  transition: color 300ms ease-in-out;
}

// todo remove class name .menu-menu-principale-container

// .main-navigation, .menu-menu-principale-container, .menu-menu-principale-container .menu, .li-custom-walker {
//   @apply flex;
//   @apply flex-grow;
// }

// .menu-menu-principale-container .menu {
//   @apply flex-col;
//   @apply justify-start;
// }

.main-navigation, 
.main-navigation > div, 
.main-navigation .menu, 
.li-custom-walker {
  @apply flex;
  @apply flex-grow;
  user-select: none;
}

.main-navigation .menu {
  @apply flex-col;
  @apply justify-start;
}

.li-custom-walker {
  @apply items-center;
  @apply relative;
  @apply justify-start;
}

li.li-custom-walker img {
  visibility: hidden;
  left: -20vw;
  top: -25vh;
  width: 40vw;
  height: 50vh;
  object-fit: cover;
  transition: opacity 300ms ease-in-out;
  @apply opacity-0;
  -webkit-user-drag: none;
}

.nav-link__bottom-line {
  @apply absolute;
  @apply bottom-0;
  @apply left-0;
  @apply bg-white;
  @apply h-px;
  @apply opacity-0;
  mix-blend-mode: difference;
  width: 0%;
}

@media screen and (min-width: 1100px) {

  .nav-link__bottom-line {
    @apply opacity-100;
  }

  .li-custom-walker {
    @apply justify-end;
  }
  
  li.li-custom-walker:nth-child(4n+1) {
    padding-right: 12vw;
  }
  
  li.li-custom-walker:nth-child(4n+2) {
    padding-right: 20vw;
  }
  
  li.li-custom-walker:nth-child(4n+3) {
    @apply justify-start;
    padding-left: 10vw;
  }
  
  li.li-custom-walker:nth-child(4n+4) {
    @apply justify-start;
    padding-left: 20vw;
  }
  
  // li.li-custom-walker:nth-child(5n+5) {
  //   padding-right: 15vw;
  // }

  li.li-custom-walker:last-child {
    align-self: flex-end;
    width: max-content;
    padding-right: 15vw;
  }

  li.li-custom-walker:hover {
    z-index: 100;
  }
  
  li.li-custom-walker:hover img {
    @apply opacity-100;
    visibility: visible;
    pointer-events: none;
    z-index: 10;
  }

}

.social-nav ul li, 
.social-nav ul li a, 
.social-nav ul li span, 
.social-nav ul li:after {
  font-size: 20px;
  color: white;
  mix-blend-mode: difference;
  transition: color 300ms ease-in-out;
}

#site-navigation.main-navigation ul:hover li.li-custom-walker a, 
#site-navigation.main-navigation ul:hover li.li-custom-walker span,
.social-nav ul:hover li a, 
.social-nav ul:hover li span, 
.social-nav ul:hover li:after  {
  @apply text-gray-500;
}

.social-nav ul li:hover a, 
.social-nav ul li:hover span, 
.social-nav ul li:hover:after {
  mix-blend-mode: inherit;
  @apply text-white;
}


#site-navigation.main-navigation ul li.li-custom-walker:hover a, 
#site-navigation.main-navigation ul li.li-custom-walker:hover span:not(.nav-link__bottom-line) {
  z-index: 50;
  mix-blend-mode: inherit;
  @apply text-white;
}

@media screen and (max-width: 768px) {

  a.logo-main {
    left: 40px;
    top: 30px;
  }

  a.logo-main img {
    height: 40px;
  }

  .main-navigation .menu {
    @apply px-8; 
    @apply pt-10;
  }

  .li-custom-walker {
    flex-grow: 0;
    min-height: 60px;
  }

  #site-navigation.main-navigation ul li.li-custom-walker a, 
  #site-navigation.main-navigation ul li.li-custom-walker span:not(.nav-link__bottom-line) {
    @apply text-white;
    @apply w-full;
    font-size: 30px;
    mix-blend-mode: inherit;
  }

  .lines-wrapper {
    @apply w-8;
  }

  .trg-menu__wrapper {
    top: 25px;
    right: 20px;
  }

  .trg-menu__lang, .trg-menu {
    @apply h-12;
    @apply w-12;
  }
}

.li-custom-walker:last-child .nav-link__bottom-line {
  display: none;
}

// main menu social nav

.menu-fixed .social-nav {
  @apply absolute;
  bottom: 50px;
  left: 50px;
}

.social-nav ul,
.social-nav ul li {
  @apply flex;
}



.social-nav ul li:after {
  content: '/';
  display: block;
  @apply mx-2;
}

.social-nav ul li:last-child:after {
  display: none;
} 


// audio

.audio__wrapper {
  @apply fixed;
  @apply z-10;
  @apply text-white;
  right: 5vw;
  bottom: 7vh;
  mix-blend-mode: difference;
}

.trg-menu__lang span {
  border-radius: 50%;
}

.circle-cursor.circle-cursor--inner,#cursor--content {
  @apply absolute;
  pointer-events: none;
  user-select: none;
}

.circle-cursor.circle-cursor--inner {
  mix-blend-mode: difference;
  border-radius: 50%;
  z-index: 9999;
  height: 5px;
  width: 5px;
  @apply bg-white;
  left: -2.5px;
  top: -2.5px;
}

#cursor--content {
  @apply uppercase;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-center;
  @apply text-white;
  line-height: 1.3em;
  font-size: 14px;
  width: 100px;
  height: 100px;
  left: -50px;
  top: -50px;
  z-index: 99999;
  mix-blend-mode: difference;
}

// #cursor--content * {
//   z-index: 99999;
// }

// #cursor--content .cursor-icon {
  
// }

#cursor--outer {
  @apply h-screen;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  width: 100%;
  mix-blend-mode: difference;
}

// wines page

article.wine-post {
  @apply relative;
}

.wine-header {
  @apply items-start;
  @apply flex;
  @apply justify-between;
  @apply container;
  @apply flex-wrap;
  margin-bottom: 20vw;
  // margin-bottom: #{"max( 40vw, 300px )"};
  padding-top: 150px;
}

#wine-canvas {
  width: 100%;
}

#wine-canvas__wrapper{
  
  max-width: 100%;
  width: 70%;
  margin: 0 auto;
  // transform: translateX(-50%);
  // border: 1px solid black;
}

@media screen and (min-width: 1100px) {

  .wine-header {
    padding-top: 218px;
    min-height: #{"max(1080px, 56vw)"};
  }

  #wine-canvas__wrapper {
    @apply absolute;
    right: 42%;
    top: 80px;
    width: #{"max(350px, 20%)"};
  }

  .wine-header__title-wrapper {
    flex-basis: 33%;
    z-index: 3;
  }
  
  .wine-sidebar {
    flex-basis: 32%;
    z-index: 3;
  }
}

.wine-sidebar__fields {
  margin-bottom: 100px;
}

.wine-sidebar li {
  max-width: 500px;
  font-size: 1.25rem;
  line-height: 1.1em;
}

.wine-title {
  @apply uppercase;
  @apply text-brand;
  @apply text-wine;
}

.wine-sidebar__icon-wrapper {
	top: 0;
	left: -70px;
	width: 54px;
}

.wine-sidebar__actions {
  @apply flex;
  @apply justify-between;
  @apply flex-wrap;
}

.wine-sidebar__actions .wine-action {
  @apply flex-grow;
  @apply mr-8;
}

.wine-sidebar__actions .wine-action, .button {
  @apply border-white;
  @apply text-black;
  @apply bg-white;
  transition: all 300ms ease-in-out;
  @apply uppercase;
  @apply mb-8;
  @apply border;
  @apply text-center;
  @apply rounded-full;
  @apply whitespace-nowrap;
  @apply px-4;
  font-size: 15px;
  line-height: 3rem;
}

.wine-sidebar__actions .wine-action:last-child {
  @apply mr-0;
}

.wine-sidebar__actions .wine-action.outlined, .button {
  @apply border-black;
  @apply text-black;
  @apply bg-white;
}

.button {
  @apply px-16;
  width: max-content;
}

.wine-sidebar__actions .wine-action.outlined:hover, .button:hover {
  @apply bg-brand;
  @apply border-brand;
  @apply text-white;
}

@media screen and (max-width: 1099px) {
  .wine-header__title-wrapper, .wine-sidebar {
    @apply w-full;
    z-index: 3;
  }

  .wine-sidebar .wine-sidebar__fields {
    padding-left: 56px;
  }

  #wine-canvas__wrapper {
    @apply mb-16;
    width: #{"min(350px, 70%)"};
  }

  .wine-title {
    font-size: 70px;
  }

  .wine-sidebar__actions {
    @apply flex-col;
    @apply justify-center;
  }

  .wine-sidebar__actions .wine-action {
    @apply mr-0;
  }
  

}

// GUTEMBERG CONTENT

#f-page-content, 
#f-page-header, 
#f-search-page-header, 
#f-archive-page-header,
#f-search-page-content {
  @apply container;
}

#f-page-content, 
#f-search-page-content {
  padding-bottom: 3rem;
  overflow: hidden;
}

.search-item-header {
  @apply py-12;
}

.search-item-header .entry-title {
  @apply text-big;
}

.search-item-content {
  @apply mb-24;
}

#f-page-header, 
#f-search-page-header, 
#f-archive-page-header {
  padding-top: 218px;
  padding-bottom: 3rem;
}

#f-page-header h1, 
#f-search-page-header h1, 
#f-archive-page-header h1 {
  @apply text-wine;
}

@media screen and (max-width: 1099px) { 
  #f-page-header, 
  #f-search-page-header, 
  #f-archive-page-header {
    padding-top: 150px;
    padding-bottom: 3rem;
  }
  
  #f-page-header h1, 
  #f-search-page-header h1, 
  #f-archive-page-header h1 {
    font-size: 70px;
  }

}

#wine-content .wp-block-columns {
  @apply container;
  @apply items-stretch;
}

.wp-block-columns, .runner {
  padding-top: calc(60px + 3vh);
  padding-bottom: calc(60px + 3vh);
}

// .wp-block-image, 
.entry-content h2:not(.runner), 
.entry-content h3:not(.runner), 
.entry-content ul li, 
.entry-content p:not(.runner) {
  @apply opacity-0;
}

figure.portrait, 
figure.square,
figure.landscape {
  position: relative;
}

figure.portrait::after, 
figure.square::after,
figure.landscape::after {
  display: block;
  content: "";
}

figure.portrait::after {
  padding-bottom: 126%;
}

figure.square::after {
  padding-bottom: 100%;
}

figure.landscape::after {
  padding-bottom: 66.82%;
}


figure.square img,
figure.landscape img,
figure.portrait img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#overlay {
  @apply hidden;
  @apply opacity-0;
  @apply w-full;
  @apply h-screen;
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
  background-color: rgba(255,255,255,0.98);
  visibility: hidden;
}

#page #overlay-content {
  display: none;
}

#overlay-close-action {
  @apply absolute;
  @apply top-20;
  @apply right-20;
  height: 50px;
  width: 50px;
}

#overlay-content .container > div {
  max-width: 450px;
}

#overlay-content {
  @apply w-full;
}

.overlay-content__form-wrapper {
  @apply container;
  @apply flex; 
  @apply flex-wrap;
  @apply mx-auto;
  @apply justify-between;
  @apply py-0;
  @apply px-6;
  max-width: 1080px;
}

.overlay-content__form-wrapper > div {
  max-width: 450px;
}

.overlay-content__form-wrapper .overlay-title {
  font-size: calc(1.6vw + 2.5rem);
  @apply mb-6;
}

.overlay-content__form-wrapper .overlay-description {
  font-size: clamp(20px,3.7vw,40px);
  line-height: 0.8em;
}

@media screen and (max-width: 767px) {
  .overlay-content__form-wrapper {
    @apply justify-center;
    @apply px-12;
  }

  .overlay-content__form-wrapper > div {
    flex-grow: 1;
  }

  #overlay-close-action {
    @apply top-16;
    @apply right-16;
    height: 35px;
    width: 35px;
  }
}


#gallery-btn__wrapper {
  @apply flex;
  @apply justify-center;
  @apply py-4;
}

// figure.landscape img {
//   width: 100%;
//   height: 60%;
//   object-fit: cover;
// }

// figure.portrait img {
//   max-width: 100%;
//   // width: max(500px, 40vw);
//   // height: max(340px, 50vw);
//   width: 40vw;
//   height: 50vw;
//   object-fit: cover;
// }

// /* Position child elements relative to this element */
// .aspect-ratio-box {
//   position: relative;
// }

// /* Create a pseudo element that uses padding-bottom to take up space */
// .aspect-ratio-box::after {
//   display: block;
//   content: "";
//   /* 16:9 aspect ratio */
//   padding-bottom: 56.25%;
// }

// /* Image is positioned absolutely relative to the parent element */
// .aspect-ratio-box img {
//   /* Image should match parent box size */
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
// }


// home

.home-header {
  z-index: 1;
  @apply uppercase;
  @apply items-center;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply pointer-events-none;
}

// .home-wines__wrapper {

// }

.wine-container a {
  display: block;
  position: relative;
}

.wine-container .wine-home-image, 
.wine-container .image-overlay {
  @apply object-cover;
  height: 200px;
}

.wine-container .image-overlay {
  @apply absolute;
  
}

@media screen and (min-width: 1100px) {

  .home-header {
    @apply absolute;
    @apply top-0;
    @apply h-screen;
    @apply left-0;
    @apply w-full;
    height: calc(var(--vh, 1vh) * 100);
  }

  .home-header .text-home {
    @apply text-center;
  }

  .home-header .text-payoff {
    max-width: 717px;
    font-size: clamp( 30px, calc(1.6vw + 1.88rem), 60px);
  }

  .home-wines__wrapper {
    @apply absolute;
    @apply w-full; 
    @apply h-screen;
    @apply top-0;
    @apply left-0;
    height: calc(var(--vh, 1vh) * 100);
  }

  .home-wines__wrapper .wine-container {
    @apply opacity-0;
    @apply absolute;
    visibility: hidden;
  }

  .wine-container .image-overlay {
    @apply opacity-0;
    @apply w-full;
    @apply h-full; 
    top: 0;
    left: 0;
  }

  .wine-container.top, .wine-container.top img {
    width: 28vw;
    height: 50vh;
    transform-origin: top center;
  }

  .wine-container.top {
    left: 26vw;
    top: 0;
  }

  .wine-container.right, .wine-container.right img {
    width: 16.5vw;
    height: 33vh;
    transform-origin: center right;
  }

  .wine-container.right {
    left: 83.5vw;
    top: 51vh;
  }

  .wine-container.bottom, .wine-container.bottom img {
    width: 31vw;
    height: 30vh;
    transform-origin: bottom center;
  }

  .wine-container.bottom {
    top: 70vh;
    left: 47vw;
  }

  .wine-container.left, .wine-container.left img {
    height: 30vh;
    width: 20vw;
    transform-origin: center left;
  }

  .wine-container.left {
    left: 0;
    top: 51vh;
  }

}

@media screen and (max-width: 1099px) {
  .home-header { 
    @apply container;
    padding-top: 150px;
    padding-bottom: 100px;
  }

  .home-header .text-home {
    margin-bottom: 15px;
    @apply text-left;
  }

  .home-header .text-payoff {
    @apply text-2xl;
  }

  .home-wines__wrapper {
    @apply container;
  }

  .wine-container {
    margin-bottom: 90px;
  }

  .wine-container .wine-home-image,
  .wine-container .image-overlay {
    width: 90%;
  }

  .wine-container .wine-home-image {
    margin-left: 10%;
    margin-bottom: 10px;
  }

  .wine-container:nth-child(even) a {
    padding-top: 80px;
  }

  .wine-container .image-overlay {
    top: 0;
    left: 0;
  }

  .wine-container:nth-child(odd) .wine-home-image {
    margin-bottom: 90px;
  } 

  .wine-container:nth-child(odd) .image-overlay {
    top: 80px;
  }

  .wine-container .wine-container__title {
    @apply text-3xl;
    @apply py-6;
    @apply uppercase;
  }


}

.home-wines__wrapper {
  z-index: 0;
}

.entry-content {
  padding-bottom: 200px;
  overflow-x: hidden;
}

.entry-content h2, .entry-content h3 {
  @apply text-big;
  margin-bottom: 2rem;
}

.entry-content p {
  font-size: calc( 16px + 0.3vw);
  // max-width: 580px;
}

.entry-content p.has-text-align-center {
  max-width: 850px;
  @apply mx-auto;
}

.entry-content .wp-block-image {
	overflow: hidden;
  // clip-path: inset(100% 0px 0px);
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
}

.entry-content p, .entry-content .wp-block-image {
  margin-bottom: 3.125rem;
}


.entry-content .runner {
  @apply text-runner;
  @apply uppercase;
  width: max-content;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  
  .margin-right {
    margin-right: 6vw;
  }
  
  .margin-left {
    margin-left: 6vw;
  }

  .entry-content .wp-block-column:not(:first-child) {
    margin-left: 4rem;
  }

}

.margin-top {
  margin-top: 6vw;
}
  
.margin-bottom {
  margin-bottom: 6vw;
}

.entry-content .bottom, 
.entry-content .middle,
.entry-content .top {
  @apply flex;
  @apply flex-col;
  
}

.entry-content .bottom {
  @apply justify-end;
}

.entry-content .middle {
  @apply justify-center;
}

.entry-content .top {
  @apply justify-start;
}




.gallery__wrapper {
  @apply fixed;
  @apply top-0; 
  @apply left-0;
  @apply w-full;
  @apply h-screen;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
  pointer-events: none;
}

.gallery__wrapper .gallery__item {
  @apply absolute;
  @apply w-full;
  @apply h-full;
  @apply top-0;
  @apply left-0;
  @apply opacity-0;
}

.gallery__wrapper .gallery__item img {
  @apply h-full;
  @apply w-full;
  object-fit: contain;
  pointer-events: none;
}

.gallery-controls__wrapper {
  @apply flex;
  @apply absolute;
  @apply z-50;
  @apply h-full;
  @apply w-full;
  @apply items-center;
  @apply justify-between;
  mix-blend-mode: difference;
}

.gallery-controls__wrapper .gallery-controls__button {
  height: 80px;
  width: 80px;
  pointer-events: all;
  @apply flex;
  @apply justify-center;
  @apply text-white;
  // border: 1px solid white;

}

.gallery-controls__wrapper {
  @apply px-12;
}

.gallery-close {
  @apply absolute;
  @apply text-white;
  z-index: 100;
  height: 35px;
  width: 35px;
  top: 30px;
  right: 35px;
  pointer-events: all;
}

.gallery-close svg {
  @apply h-full;
  @apply w-full;
}

@media screen and (max-width: 768px) {

  .gallery-controls__wrapper {
    @apply px-6;
    @apply py-16;
    @apply items-end;
  }

  .gallery__wrapper .gallery__item img {
    @apply object-contain; 
  }

  .gallery-controls__wrapper .gallery-controls__button {
    width: 30px;
    height: 30px;
  }

  .gallery-controls__wrapper .gallery-controls__button svg {
    height: 100%;
  }

  .gallery-controls__wrapper .gallery-controls__button svg path {
    stroke: white;
    stroke-width: 8px;
  }
}




#colophon {
  @apply bg-black;
  padding-top: 15vw;
  padding-bottom: 10vw;
}

#colophon .colophon__row {
  @apply container;
  @apply flex;
  @apply flex-wrap;
  @apply items-stretch;
}

#colophon .colophon__row:first-child {
  @apply relative;
} 

#colophon .colophon__row:first-child:after {
  content: '';
  @apply h-px;
  @apply block;
  @apply relative;
  @apply w-full;
  @apply bg-white;
  @apply opacity-30;
  order: 999;
  margin-top: 7vw;
  margin-bottom: 5vw;
}

#colophon .colophon__row .colophon__col {
  @apply w-full;
}

#colophon .colophon__row > div {
  @apply flex;
  @apply flex-col;
  @apply justify-between;
}

#colophon .colophon__row.colophon__row__credits > div {
  @apply justify-center;
}

#colophon .colophon__row .widget {
  margin-top: 70px;
}

#colophon .colophon__row .widget:first-child {
  margin-top: 0px;
}

.widget .widget-title {
  @apply uppercase;
  @apply mb-4;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  opacity: 0.35;
}

.widget .textwidget {
  font-size: 20px;
}

#colophon .colophon__row.colophon__row__credits .credits {
  @apply flex;
  @apply flex-row;
  @apply justify-between;
  @apply items-center;
}

#colophon .colophon__row .credits .widget {
  @apply mt-0;
}

#colophon .colophon__row .credits .widget .textwidget {
  opacity: 0.35;
}

@media screen and (max-width: 768px) {
  #colophon .widget .textwidget {
    opacity: 0.35;
  }

  #colophon .social-nav ul {
    justify-content: center;
  }

  #colophon .credits section:last-child {
    display: none;
  }

  #colophon .colophon__row:first-child:after {
    background-color: transparent;
  }

  #colophon .colophon__row .credits {
    @apply flex-wrap;
    border-bottom: 1px solid white;
    padding-bottom: 50px;
    margin-bottom: 20px;
  }

  #colophon .colophon__row .credits > * {
    flex-basis: 28%;
  }
}


// sound

#toggle-sound {
  @apply fixed;
  @apply text-white;
  @apply flex;
  @apply items-center;
  @apply opacity-0;
  visibility: hidden;
  overflow: hidden;
  width: 36px;
  height: 38px;
  bottom: 65px;
  right: 65px;
  mix-blend-mode: difference;
  z-index: 100;
}

#toggle-sound .audio-sound-icon {
  // height: 38px;
  flex: 1 0 auto;
  height: 38px;
}

#toggle-sound .icon {
  height: 38px;
}

@media screen and (max-width: 768px) {
  #toggle-sound {
    width: 28px;
    height: 28px;
    // bottom: 65px;
    // right: 65px;
  }
  
  #toggle-sound .audio-sound-icon {
    height: 28px;
  }
  
  #toggle-sound .icon {
    height: 28px;
  }
}

.circle-cta {
  @apply rounded-full;
  @apply border;
  @apply border-black;
  @apply text-center;
  @apply flex;
  @apply items-center;
  @apply uppercase;
  height: 140px;
  width: 140px;
  margin: 20px auto;
  font-size: 18px;
}

.arrow-action .arrow-action__text {
  @apply text-center;
  @apply mb-4;
  @apply mt-16;
  @apply text-lg;
  @apply uppercase;
}

.arrow-action .arrow-action__arrow {
  @apply mx-auto;
}
